import React, { Component } from 'react';
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import styleList from "../../content/grid_style.json"
import iconList from "../../content/grid_icons.json"

const isBrowser = typeof window !== "undefined";

class PortretItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offsetTop: 0,
      offsetLeft: 0,
    }
    this.getAge = this.getAge.bind(this);
  }

  componentDidMount() {
    if (isBrowser) {
      this.setState({
        offsetTop: this.props.context.elOffsetTop,
        offsetLeft: this.props.context.elOffsetLeft,
      });
    }
  }

  componentWillUnmount() {
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props !== nextProps) {
      this.setState({
        offsetTop: nextProps.context.elOffsetTop,
        offsetLeft: nextProps.context.elOffsetLeft,
      });
      return false;
    }

    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  getAge(person) {
    let birth = new Date(person.field_birth_date).getFullYear();
    let date = new Date().getFullYear();

    if (typeof person.field_date_of_death != 'undefined') {
      date = new Date(person.field_date_of_death).getFullYear();
    }

    return parseInt(date - birth);
  }

  render() {
    const { context, image, location } = this.props;
    let pathPrefix = '';
    let img = null;
    let styleIndex = context.styleIndex;

    // Set image.
    if (image !== null && typeof image[0] !== 'undefined' && context.title !== null) {
      img = <GatsbyImage image={image[0].childImageSharp.gatsbyImageData} alt={context.title} />
    }

    // Set content.
    let age = this.getAge(context);
    let group = context.relationships.field_ref_group;
    let name;
    name = context.field_first_name !== null ? context.field_first_name.trim() : null;
    group = group !== null ? group.title : '_undefined';

    // Build alias.
    let alias = null;
    if (location) {
      let path = location.pathname;
      if (path !== undefined && path.split('/').includes("amp")) {
        pathPrefix = '/amp'
      }

      if (context.relationships.field_ref_group && context.relationships.field_ref_group.title) {
        let sanitized_title = context.relationships.field_ref_group.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
          .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
          .replace(/--+/g, '-')	// Replaces multiple hyphens by one hyphen
          .replace(/(^-+|-+$)/, '') // Remove extra hyphens from beginning or end of the string
          .toLowerCase(); // Lowercase.
        alias = `${pathPrefix}/groepen/${sanitized_title}/`
        group = sanitized_title
      }
    }

    // Set style.
    let offsettop = this.state.offsetTop + 'px';
    let offsetleft = this.state.offsetLeft + 'px';

    let style = styleList[0];

    if (styleList[styleIndex]) {
      style = styleList[styleIndex];
    }

    let cell = this.props.context.cellSize

    let main_style = {
      position: 'absolute',
      top: offsettop,
      left: offsetleft,
      width: cell * style['width'],
      height: cell * style['height'],
    }

    let inner_style = {
      marginTop: cell * style['marginTop'],
      marginRight: cell * style['marginRight'],
      marginBottom: cell * style['marginBottom'],
      marginLeft: cell * style['marginLeft'],
      width: main_style.width - (cell * (style['marginLeft'] + style['marginRight'])),
      height: main_style.height - (cell * (style['marginTop'] + style['marginBottom'])),
    }

    // Set default random sort ID.
    let sortID = 0
    if (context.id) {
      sortID = context.id.replace(/\D+/g, '')
    }

    // Set context values for filtering.
    this.props.context.group = group;
    this.props.context.age = age;
    this.props.context.id = sortID;

    let gridIcon = null;
    let gridIconStyle = null;
    let gridIconBox = null;

    if (context.gridIcon !== null) {
      let iconData = iconList[context.gridIcon];

      let iconWidth = iconData.width * cell;
      let iconHeight = iconData.height * cell;
      let iconTop = iconData.top * cell;
      let iconLeft = iconData.left * cell;

      gridIcon = iconData.icon
      gridIconStyle = { position: 'absolute', top: iconTop, left: iconLeft, width: iconWidth, height: iconHeight }
      gridIconBox = `0 0 ${iconWidth} ${iconHeight}`
    }

    if (img === null) {
      return null
    }

    if (alias === null) {
      return null
    }

    return (
      <div className="grid__item"
        style={main_style}
        key={context.drupal_internal__nid}
      >
        <div className="grid__item--inner" style={inner_style}>
          <div className="grid__item--inner-wrapper">
            {img !== null && img}
            {alias !== null &&
              <Link to={alias} className="grid__item--link" state={{ prevPath: `${pathPrefix}/portretten/` }}>
                Bekijk group {context.relationships.field_ref_group.title}
              </Link>
            }
            <div className="grid__item--overlay">
              <span>
                {name}{!isNaN(age) && `, ${age} jaar`}
              </span>
            </div>
          </div>
        </div>

        {gridIcon !== null &&
          <svg style={gridIconStyle} xmlns="http://www.w3.org/2000/svg" viewBox={gridIconBox}>
            <use href={`#grid-icon-${gridIcon}`} />
          </svg>
        }

      </div>
    );
  }
}

export default PortretItem;
