import React from "react";

import "./css/top_bar.css";

import IconSearch from "../images/icons/search.svg";
import IconArrows from "../images/icons/arrows.svg";

const _ = require("lodash");
const isBrowser = typeof window !== "undefined";
const filters = [
  {
    'value': 'id',
    'sortAsc': 1,
    'title': 'Willekeurig',
  },
  {
    'value': 'age',
    'sortAsc': 1,
    'title': 'Van jong naar oud',
  },
  {
    'value': 'age',
    'sortAsc': 0,
    'title': 'Van oud naar jong',
  },
  {
    'value': 'group',
    'sortAsc': 1,
    'title': 'Per groep (A-Z)',
  },
  {
    'value': 'group',
    'sortAsc': 0,
    'title': 'Per groep (Z-A)',
  },
];

let timeoutHandle;
const SEARCH_TIMEOUT = 150;

class FilterBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_data: [],
      full_data: [],
      sortBy: 'id',
      sortAsc: true,
      activeFilterID: 0,
      searchValue: '',
      searchActive: false,
      filterActive: false,
    };
    this.setFilter = this.setFilter.bind(this);
    this.doFilter = this.doFilter.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.doSearch = this.doSearch.bind(this);
    this.getAge = this.getAge.bind(this);
    this.getGroup = this.getGroup.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }

  componentDidMount() {
    if (isBrowser) {
      let filters = ['id', 'true', 0];
      let search = '';

      if (localStorage.getItem('filter')) {
        filters = localStorage.getItem('filter').split(',');
      }

      if (localStorage.getItem('search')) {
        search = localStorage.getItem('search');
      }

      // Set full data.
      this.setState({
        full_data: this.props.data,
        filter_data: this.props.data,
        sortBy: filters[0],
        sortAsc: filters[1] === 'true',
        activeFilterID: parseInt(filters[2]),
        searchValue: search,
      }, () => {
        this.doSearch();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Props should be set once.
    if (this.props !== nextProps) {
      return false
    }

    // Props should be set once.
    if (nextState.filter_data !== this.state.filter_data) {
      this.setState({
        filter_data: nextState.filter_data,
      }, () => {
        this.doSearch();
      });
      return false
    }

    // Update render after filter toggle.
    if (nextState !== this.state) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    clearTimeout(timeoutHandle);
  }

  setFilter(e) {
    if (isBrowser) {
      let activeFilterID = parseInt(e.target.attributes['data-filter'].value)
      if (activeFilterID !== this.state.activeFilterID) {
        let sortBy = e.target.attributes['data-value'].value
        let sortAscending = e.target.attributes['data-sort-asc'].value === "1" ? true : false

        // Set persistent filters.
        localStorage.setItem('filter', [sortBy, sortAscending, activeFilterID])

        this.setState({
          sortBy: sortBy,
          sortAsc: sortAscending,
          activeFilterID: activeFilterID,
          filterActive: false,
        }, () => {
          this.doFilter();
        });
      }
    }
  }

  doFilter() {
    if (isBrowser) {
      let start_data = this.state.filter_data
      let sorter = this.state.sortBy
      let t = this

      // Sort data.
      var result_data = _.sortBy(start_data, [function(o) {
        let value;
        switch (sorter) {
          case 'age':
            value = t.getAge(o.context.data.field_birth_date, o.context.data.field_date_of_death);
            break;
          case 'group':
            value = t.getGroup(o.context.data.relationships);
            break;
          default:
            value = o.context.data.id

        }
        return value;
      }]);

      // Set sort order.
      if (!this.state.sortAsc) {
        result_data.reverse();
      }

      // Update data.
      this.props.update(result_data);
    }
  }

  setSearch(e) {
    if (isBrowser) {
      clearTimeout(timeoutHandle);
      let t = this;
      let value = e.target.value;
      localStorage.setItem('search', value)
      t.setState({ searchValue: value }, () => {
        timeoutHandle = setTimeout(function () {
          t.doSearch();
      }, SEARCH_TIMEOUT);
      });
    }
  }

  doSearch() {
    let value = this.state.searchValue;
    let new_data = [];
    let all_data = this.state.full_data;

    if (value === '') {
      new_data = all_data
    }
    else {
      let searchvalue = value.toLowerCase();
      // Search value in data.
      for (var x = 0; x < all_data.length; x++) {
        if (all_data[x].context.data.field_first_name) {
          let itemvalue = all_data[x].context.data.field_first_name.toLowerCase()
          if (itemvalue.indexOf(searchvalue) !== -1) {
            new_data.push(all_data[x]);
          }
        }
      }
    }

    this.setState({
      filter_data: new_data,
    }, () => {
      // Set filtered data.
      this.doFilter();
    });

  }

  getAge(start, end) {
    let birth = new Date(start).getFullYear();
    let date = new Date().getFullYear();

    if (typeof end != 'undefined') {
      date = new Date(end).getFullYear();
    }

    return parseInt(date - birth);
  }

  getGroup(data) {
    // Sanitize.
    let group = '_'
    if (data.field_ref_group) {
      group = data.field_ref_group.title
    }
    // Sanitize.
    group = group.replace(/[^\w\s]/gi, '').replace(/ /g, '-').replace(/--/g, '-').toLowerCase()
    return group;
  }

  toggleActive(e) {
    if (isBrowser) {
      if (e.target.value === "search") {
        this.setState({ searchActive: !this.state.searchActive });
        this.setState({ filterActive: false });
      } else {
        this.setState({ filterActive: !this.state.filterActive });
        this.setState({ searchActive: false });
      }
    }
  }

  render() {
    return (
      <div className="topbar">
        <div className="topbar--block">
          <button aria-label="Zoeken" onClick={this.toggleActive} value="search" className={this.state.searchActive ? 'topbar--icon topbar--icon--search active' : 'topbar--icon topbar--icon--search'}>
            <IconSearch />
          </button>
          <div className="topbar--block-wrapper topbar--block-wrapper-bg topbar--block-wrapper-flex">
            <label htmlFor="search" className="search__label">Alles van </label>
            <input id="search" type="text" className="search__item" placeholder="Naam" onChange={this.setSearch} value={this.state.searchValue} />
          </div>
        </div>

        <div className="topbar--block">
          <button aria-label="Sorteren" onClick={this.toggleActive} value="sort" className={this.state.filterActive ? 'topbar--icon topbar--icon--sort active' : 'topbar--icon topbar--icon--sort'}>
            <IconArrows />
          </button>
          <div className="topbar--block-wrapper topbar--block-wrapper-bg">
            <span className="topbar--block-title">Sorteer</span>
            {filters.map((filter, index) => {
              let classname = index === this.state.activeFilterID ? "topbar--block-item active" : "topbar--block-item"
              return (
                <button
                  key={index}
                  className={classname}
                  data-filter={index}
                  data-value={filter.value}
                  data-sort-asc={filter.sortAsc}
                  onClick={this.setFilter}>
                  {filter.title}
                </button>
              )
            })}

          </div>
        </div>
      </div>
    )
  }
}

export default FilterBlock
