import React from "react";

import "./css/grid.css";

import TopBar from "./filterBlock";
import SmartGrid from "./smartGrid";
import Loader from './loader';

import IconCam from "../images/portret-icons/avw-camera-3x3.svg";
import IconCirkel from "../images/portret-icons/avw-cirkel-2x2.svg";
import IconDeur from "../images/portret-icons/avw-deur-2x3.svg";
import IconDiamand from "../images/portret-icons/avw-diamand-3x3.svg";
import IconPlus from "../images/portret-icons/avw-plus-3x3.svg";
import IconRolschaats from "../images/portret-icons/avw-rolschaats-5x5.svg";
import IconScroll from "../images/portret-icons/avw-scroll-5x5.svg";
import IconTrompet from "../images/portret-icons/avw-trompet-7x3.svg";
import IconVlag from "../images/portret-icons/avw-vlag-4x5.svg";

const isBrowser = typeof window !== "undefined";

class PersonGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      full_data: [],
      cellsize: 25,
      ready: false,
      sortBy: 'id',
      sortAsc: true,
      scrollPosSet: false,
    };
    this.handleResize = this.handleResize.bind(this);
    this.setGridSize = this.setGridSize.bind(this);
    this.updateData = this.updateData.bind(this);
    this.setScrollReady = this.setScrollReady.bind(this);
  }

  componentDidMount() {
    // Set person data.
    this.setState({
      data: this.props.persons,
      full_data: this.props.persons,
    }, () => {
      this.handleResize();
    });

    if (isBrowser) {
      // Set cell size after resize.
      window.addEventListener("resize", this.handleResize, true);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.ready) {
      if ((this.state.data !== nextState.data) || (this.state.cellsize !== nextState.cellsize)) {
        return true;
      }

      if (!this.state.scrollPosSet && nextState.scrollPosSet) {
        return true;
      }
    }

    return false;
  }

  componentWillUnmount() {
    if (isBrowser) {
      window.removeEventListener('resize', this.handleResize, true)
      window.removeEventListener('scroll', this.setScrollPos, true)
    }
  }

  handleResize() {
    this.setState({
      ready: false,
    }, () => {
      this.setGridSize();
    });
  }

  setGridSize() {
    let cellsize = 0;
    if (isBrowser) {

      let width = window.innerWidth
      let innerWidth = document.body.clientWidth
      if (width > 960) {
        cellsize = innerWidth / 48
      } else if (width > 480) {
        cellsize = innerWidth / 24
      } else {
        cellsize = innerWidth / 12
      }

      cellsize = (Math.round(cellsize * 100) / 100).toFixed(2)

      // Set ready mark.
      this.setState({
        ready: true,
        cellsize: cellsize
      });
    }
  }

  updateData(filteredData) {
    this.setState({
      data: [],
      ready: false,
    }, () => {
      this.setState({
        data: filteredData,
        ready: true,
      }, () => {
        this.handleResize()
      });
    });
  }

  setScrollReady() {
    if (isBrowser) {
      // Restore scroll position.
      if (!this.state.scrollPosSet) {
        this.setState({
          scrollPosSet: true,
        }, () => {
          if (localStorage.getItem('scrollPos')) {
            // Update layout grid.
            let event = new CustomEvent('resize');
            document.scrollingElement.dispatchEvent(event);

            document.body.scrollTop = localStorage.getItem('scrollPos');
            document.documentElement.scrollTop = localStorage.getItem('scrollPos');
          }
          window.addEventListener('scroll', this.setScrollPos, true)
        });
      }
    }
  }

  setScrollPos(e) {
    if (e.target.scrollingElement) {
      localStorage.setItem('scrollPos', e.target.scrollingElement.scrollTop)
    } else {
      localStorage.setItem('scrollPos', e.target.scrollTop)
    }
  }

  render() {
    return (
      <>
        <div style={{display: 'none'}}>
          <IconCam />
          <IconCirkel />
          <IconDeur />
          <IconDiamand />
          <IconPlus />
          <IconRolschaats />
          <IconScroll />
          <IconTrompet />
          <IconVlag />
        </div>

        {this.state.ready && this.state.scrollPosSet && this.state.full_data.length > 0 &&
          <TopBar data={this.state.full_data} update={this.updateData}></TopBar>
        }

        {!this.state.ready ? <Loader /> : null}

        <SmartGrid
          className={this.state.ready ? "grid__wrapper" : "grid__wrapper loading"}
          dataReady={this.setScrollReady}
          location={this.props.location}
        >
          { this.state.data }
        </SmartGrid>
      </>
    )
  }
}

export default PersonGrid
