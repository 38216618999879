import React from "react"
import { graphql } from "gatsby"
import Seo from '../components/seo';

import Grid from "../components/personGrid";

const Portretten = (props) => {
  return (
    <>
      <Seo
        title="Portretten"
        pathname="/portretten/"
        article={false}
      />
      <Grid persons={props.data.allSitePage.nodes} location={props.location} />
    </>
  );
}

export const query = graphql`
query PortretsQuery {
  allSitePage(filter: {context: {amp: {eq: false}, bundle: {eq: "Person"}}}) {
    nodes {
      images_single_person {
        childImageSharp {
          gatsbyImageData(
            width: 400
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP]
            aspectRatio: 0.66
          )
        }
      }
      context {
        data {
          id
          title
          field_first_name
          field_last_name
          field_birth_date
          drupal_internal__nid
          relationships {
            field_ref_group {
              title
              drupal_internal__nid
            }
          }
        }
      }
    }
  }
}
`

export default Portretten
