import React from "react";
import styleList from "../../content/grid_style.json"
import iconList from "../../content/grid_icons.json"

import PortretItem from './portret_item';

const isBrowser = typeof window !== "undefined";

class SmartGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cellSize: 25,
      data: [],
      renderData: [],
      itemCount: 0,
      gridHeight: 0,
      availableCells: 0,
      ready: false,
      firstRender: true,
    };
    this.buildPersonList = this.buildPersonList.bind(this);
    this.getInViewport = this.getInViewport.bind(this);
    this.computeRenderData = this.computeRenderData.bind(this);
    this.computCellData = this.computCellData.bind(this);
  }

  componentDidMount() {
    if (isBrowser) {
      window.addEventListener('scroll', this.getInViewport, true)
      window.addEventListener('resize', this.getInViewport, true)
    }
  }

  componentWillUnmount() {
    if (isBrowser) {
      window.removeEventListener('scroll', this.getInViewport, true)
      window.removeEventListener('resize', this.getInViewport, true)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.children !== nextProps.children) {
      var personsList = this.buildPersonList(nextProps.children);

      this.setState({
        data: personsList,
        itemCount: personsList.length,
      }, () => {
        this.getInViewport();
      });
      return false;
    }

    if (!this.state.ready && nextState.ready && this.state !== nextState) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.firstRender) {
      this.setState({
        firstRender: false,
      }, () => {
        this.props.dataReady();
      });
    }
  }

  buildPersonList(data) {
    let personsList = data.map(person => {
      if (person.context.data === null) {
        return null
      }

      if (person.images_single_person === null) {
        return null
      }

      if (typeof person.images_single_person[0] === 'undefined') {
        return null
      }

      let id = person.context.data.drupal_internal__nid
      let image = person.images_single_person;

      return React.cloneElement(
        <PortretItem
          key={id}
          context={person.context.data}
          image={image}
          location={this.props.location}>
        </PortretItem>
      );
    });

    // Remove null values.
    personsList = personsList.filter(n => n)

    return personsList;
  }

  getInViewport() {
    let data = this.computCellData();
    this.setState({
      ready: false,
      cellSize: data['size'],
      availableCells: data['cells'],
      renderData: [],
    }, () => {
      this.computeRenderData();
    });
  }

  computCellData() {
    if (isBrowser) {
      let cellsize = 0;
      let data = []
      let width = window.innerWidth;
      let innerWidth = document.body.clientWidth

      if (width > 960) {
        cellsize = innerWidth / 48
        data['cells'] = 48;
      }
      else if (width > 480) {
        cellsize = innerWidth / 24
        data['cells'] = 24;
      }
      else {
        cellsize = innerWidth / 12
        data['cells'] = 12;
      }

      data['size'] = (Math.round(cellsize * 100) / 100).toFixed(2)

      return data
    }
  }

  computeRenderData() {
    // Get the scoll position.
    let scrollTop = document.body.scrollTop || document.documentElement.scrollTop

    // Set the boundries to render the elements in.
    let clientHeight = document.scrollingElement.clientHeight

    // Offset is highest possible item with margins.
    let offset = this.state.cellSize * 20

    // Everything above the viewport with an item height offset
    let showFrom = scrollTop - offset

    // Everything under the viewport
    let showTo = scrollTop + clientHeight + (offset / 2);

    // Render vars.
    let render_data = [];
    let data = [];
    let firstRowItem = true;

    // Previous item data.
    let prevItem = null;
    let prevStyleIndex = 0;
    let nextStyleIndex = 0;
    let prevRowHighest = 0;

    // Style vars.
    let styleIndex = 0;
    let iconIndex = 0;
    let elOffsetTop = 0;
    let elOffsetLeft = 0;
    let currRowCells = 0;

    // Loop the (filtered) data.
    for (let index = 0; index < this.state.data.length; index++) {
      // Set current item.
      data[index] = this.state.data[index];

      // Skip portrets without group.
      let hasGroup = data[index].props.context.relationships.field_ref_group;
      if (hasGroup === null) {
        continue;
      }

      // Set next style index.
      nextStyleIndex++;
      if (nextStyleIndex >= styleList.length) {
        // Last style item was reached.
        nextStyleIndex = 0
      }

      // Get previous item values.
      if (prevItem !== null) {
        // Get current item offset based on previous item.
        let prevtop = prevItem.props.context.elOffsetTop
        let prevleft = prevItem.props.context.elOffsetLeft
        let prevwidth = styleList[prevStyleIndex].width * this.state.cellSize
        let prevheight = styleList[prevStyleIndex].height * this.state.cellSize

        // Always set the highest row item.
        if ((prevtop + prevheight) >= prevRowHighest) {
          prevRowHighest = prevtop + prevheight
        }

        // Positioning based on previous item.
        if (firstRowItem) {
          elOffsetTop = prevRowHighest
          prevRowHighest = prevRowHighest + (styleList[styleIndex].height * this.state.cellSize)
          elOffsetLeft = 0;
        }
        else {
          elOffsetLeft = prevleft + prevwidth;
          elOffsetTop = prevtop
        }
      }

      // Set item data.
      data[index].props.context.itemIndex = index;
      data[index].props.context.styleIndex = styleIndex;
      data[index].props.context.cellSize = this.state.cellSize;
      data[index].props.context.elOffsetTop = elOffsetTop;
      data[index].props.context.elOffsetLeft = elOffsetLeft;

      if (iconList[iconIndex].gridOrder === styleIndex + 1) {
        data[index].props.context.gridIcon = iconIndex;
        iconIndex++;

        // Set icon index.
        if (iconIndex >= iconList.length) {
          // Last item was reached.
          iconIndex = 0
        }
      }
      else {
        data[index].props.context.gridIcon = null;
      }

      // Render only elements in range.
      if (elOffsetTop >= showFrom && elOffsetTop <= showTo) {
        // Add element to render list.
        render_data.push(data[index])
      }

      // Add current item width to row width.
      currRowCells += styleList[styleIndex].width

      // Check current row width.
      if (currRowCells + styleList[nextStyleIndex].width > this.state.availableCells) {
        // Set first item of next row.
        firstRowItem = true;
        currRowCells = 0
      }
      else {
        // Go to next item in row.
        firstRowItem = false;
      }

      // Set style index.
      if (styleIndex >= styleList.length - 1) {
        // Last style item was reached.
        styleIndex = 0
        prevStyleIndex = styleList.length - 1
      }
      else {
        prevStyleIndex = styleIndex;
        styleIndex++;
      }

      // Set previous item.
      prevItem = this.state.data[index];
    }

    // Rebuild component
    let personsList = render_data.map(person => {
      let id = person.props.context.drupal_internal__nid
      let image = person.props.image;

      return React.cloneElement(
        <PortretItem
          key={id}
          context={person.props.context}
          image={image}
          location={this.props.location}>
        </PortretItem>
      );
    });

    // Get current item top offset.
    if (prevItem) {
      let currTop = prevItem.props.context.elOffsetTop + styleList[prevItem.props.context.styleIndex].height * prevItem.props.context.cellSize

      // prevRowHighest is the top offset of the second last item.
      if (prevRowHighest < currTop) {
        prevRowHighest = currTop
      }
    }

    this.setState({
      data: data,
      gridHeight: prevRowHighest,
      ready: true,
      renderData: personsList,
    });
  };

  render() {
    let { renderData } = this.state
    return (
      <div style={{ height: this.state.gridHeight }} className={this.props.className}>
        {renderData}
      </div>
    )
  }
}

export default SmartGrid
